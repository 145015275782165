import React, { useContext, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Default } from "react-awesome-spinners";
import { Context } from "../../../context/AppProvider";
import useBalanceHistory from '../../../hooks/useBalanceHistory'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend 
);

const initialOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      max: 25000000,
      min: 0
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: false 
    },
    elements: {
      point:{
        radius: 0
      }
    },
  },
};

const initialChartData = {
  labels: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  datasets: [
    {
      fill: false,
      data: [ 0, 0, 0, 0, 0, 0, 0 ],
      lineTension: 0.4,
      borderColor: ['#DB5AEE'],
      borderWidth: 2,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0,
    },
    {
      fill: false,
      data: [ 0, 0, 0, 0, 0, 0, 0 ],
      borderColor: ['#0090FF'],
      lineTension: 0.4,
      borderWidth: 2,
      pointBorderWidth: 0,
      pointHoverRadius: 0,
      pointHoverBorderWidth: 0,
      pointRadius: 0,
      pointHitRadius: 0,
    }
  ]
}

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const PortfolioChart = () => {
  const { currentToken, walletAddress } = useContext(Context)
  const [chartData, setChartData] = useState(initialChartData)
  const [options, setOptions] = useState(initialOptions)

  const { balanceHistory: history, loading } = useBalanceHistory(walletAddress)

  useEffect(() => {
    if (loading) return
    if (history[currentToken].length < 14) return
    
    let thisWeekHistory = history[currentToken].slice(0, 7)
    let lastWeekHistory = history[currentToken].slice(7, 14)

    thisWeekHistory = {
      labels: thisWeekHistory.map(item => item.timestamp * 1000),
      values: thisWeekHistory.map(item => parseInt(Number(item.balance) / 1e18))
    }

    lastWeekHistory = {
      labels: lastWeekHistory.map(item => item.timestamp * 1000),
      values: lastWeekHistory.map(item => parseInt(Number(item.balance) / 1e18))
    }

    if (thisWeekHistory && lastWeekHistory && thisWeekHistory.values.length === lastWeekHistory.values.length) {
      const labels = thisWeekHistory.labels.map(label => days[new Date(label).getDay()])
      const thisWeekValues = thisWeekHistory.values;
      const lastWeekValues = lastWeekHistory.values;
      setOptions({
        ...options,
        scales: {
          y: Math.max(...thisWeekValues) == 0 && Math.max(...thisWeekValues) == 0 ?  
          {
            max: 25000000,
            min: 0
          } :
          {
            max: Math.max(...thisWeekValues) >= Math.max(...lastWeekValues) ? Math.max(...thisWeekValues) + 100000 : Math.max(...lastWeekValues) + 100000,
            min: 0
          }
        },
      })
      
      setChartData({
        ...chartData,
        // labels: labels,
        datasets: [
          {
            ...chartData['datasets'][0],
            data: thisWeekValues
          },
          {
            ...chartData['datasets'][1],
            data: lastWeekValues,
          }
        ]
      })
    } else console.log('no data in portfolio chart')
  }, [history, currentToken, history, loading])

  return (
    <div className="portfolioChart" style={{ minHeight: 164 }}>
      <div className="mt-3 chart-bar">
        {
          loading ?
            <div className="chart-loading-field ">
              <div className="chart-loading">
                <Default size={30} />
              </div>
            </div>
          :
          <Line
            data={chartData}
            options={options}
          />
        }
      </div>
    </div>
  )
}
export default PortfolioChart