import React, { useContext, useEffect, useState } from "react";
import logo from "../../../assets/img/Mirror Protocol Long 1.png";
import arrowUp from "../../../assets/img/arrow-small-down-r.svg"
import arrowDown from "../../../assets/img/arrow-small-down-r-6.svg"
import dollarIcon from "../../../assets/img/31684-1@2x.png"

import { Context } from "../../../context/AppProvider";

import { initialTokens } from '../../../config/tokens';

const Sidebar = () => {
  const { currentToken, setCurrentToken, tokens, walletAddress, totalBalance }= useContext(Context);
  const { selectedTokenData, setSelectedTokenData }= useContext(Context)
  const [ dollarValueState, setDollarValueState ]= useState(true);

  const date = new Date();
  const year = date.getFullYear();

  useEffect(()=> {
    onTokenSelect(currentToken);
  }, [currentToken, walletAddress])
  
  const onTokenSelect = async (currentToken) => {
    const selectedToken = Object.keys(initialTokens).find((key, i) => {
      const item = initialTokens[key]
      if(item.tokenType === currentToken) {
        setSelectedTokenData(item)
      }
    })
  }

  return (
    <div className="d-flex flex-column justify-content-between sidebar">
      <div className="d-flex flex-column align-items-center">
        <img className="logo" src={logo} alt='logo' />
        <div className="d-flex flex-column align-items-center sidebar-body">
          <div className="d-flex align-items-center">
            <button 
              className={`${dollarValueState ? "btn dollarValue-btn-active" : ''} btn`}
              onClick={()=>setDollarValueState(true)}
            >
              Dollar Value
            </button>
            <button 
              className={`${dollarValueState ? '' : "dollarValue-btn-active" } btn ms-2`}
              onClick={()=>setDollarValueState(false)}
            >
              Total Dollar Balance
            </button>
          </div>
          <div className="d-flex align-items-center value-field my-3">
            <img className="dollarImg" src={dollarIcon} alt='dollarImg' />
            <div className="font-14 color1 ms-3">{ dollarValueState? `${((tokens[currentToken]?.tokenPrice)*(tokens[currentToken]?.balance)).toFixed(5)}` : `${totalBalance.toFixed(5)}`}</div>
          </div>
          <div className="d-flex flex-column align-items-center w-100 mt-3 coin-field">
            <div className="d-flex align-items-center topbar">
              <div className="text">TOKEN</div>
              <div className="text">Price</div>
              <div className="text">Balance</div>
            </div>

            <div className="sidebar-main mt-2">
              {
                Object.keys(tokens).map((key, i) => {
                  const item = tokens[key]
                  return (
                    <div key={i} 
                      className={`d-flex justify-content-between align-items-center w-100 item ${item.tokenType === currentToken ? "item-active" : ""} ${i === 6 ? "" : "border-top"}`}
                      onClick={()=>setCurrentToken(item.tokenType)}
                    >
                      <div className="d-flex align-items-center gap-2 token-field">
                        <img className="token-item-img" src={item.tokenIcon} alt='' />
                        <div className="d-flex flex-column gap-1">
                          <div className="font-14">{item.tokenSymbol}</div>
                          <div className="d-flex align-items-center gap-1">
                            <img className="arrow-icon" src={ item.tokenChangeState ? arrowUp : arrowDown } alt='' />
                            <div className="font-12">{item.priceChange}%</div>
                          </div>
                        </div>
                      </div>

                      <div className="font-14 price-field">${Number(item.tokenPrice).toFixed(5)}</div>
                      <div className="font-14 balance-field">
                        {item.balance}{' '}
                        <span>{item.tokenSymbol}</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-footer">
        <div className="copyright-title">© {year} The Mirror Protocol</div>
        <div className="copyright-text mt-1">Powered by Block Busters Tech Inc.</div>
      </div>
    </div>
  )
}
export default Sidebar