import React, { useContext, useState, useEffect, useCallback } from 'react';
import moment from "moment";
import { Context } from '../../../context/AppProvider';
import { subscribe } from '../../../services/web3Service';

const LastTransaction = () => {  
  const [ transactions, setTransactions ] = useState([])
  const [txDisplay, setTxDisplay] = useState([])
  const { selectedTokenData, tokens } = useContext(Context);

  const sellsPercent = (selectedTokenData?.buys/(selectedTokenData?.buys+selectedTokenData?.sells)*100).toFixed(0);

  useEffect(() => {
    if (tokens && Object.keys(tokens).length > 0)
      Object.keys(tokens).forEach(async (key) => {
        const token = tokens[key]
        await subscribe({
          address: token.lpAddress,
          tokenName: token.tokenSymbol,
          symbol: token.tokenIcon,
          callback: addTransaction
        })
      })
  }, [])

  useEffect(() => {
    (async () => {
        let _transactions = Object.assign([], transactions)
      _transactions.sort((a, b) => {
        return a.timestamp > b.timestamp ? -1 : 1
      })

      // const buy
      if (_transactions.length > 50)
        setTxDisplay(_transactions.slice(0, 10))
      else 
        setTxDisplay(_transactions)
      const txns = _transactions.filter((tx) => (tx.timestamp * 1000 > (new Date().getTime() - 24 * 60 * 60 * 1000)))

      const buy = await txns.reduce((prev, tx) => {
        if (!tx.in) return prev + tx.amount
        return prev
      }, 0)

      const sell = await txns.reduce((prev, tx) => {
        if (tx.in) return prev + tx.amount
        return prev
      }, 0)

    })()

  }, [transactions])

  
  const addTransaction = useCallback((symbol, tokenIcon, result) => {
    let _transactions = Object.assign([], transactions)
    
    if (result && result.length > 0) {
      for (let  i = 0; i < result.length; i++) {
        const log = result[i]
        const date = new Date(log.timestamp * 1000);
        let data = log['data']
        data = data.slice(2, data.length)
        const sender = log['topics']['1']
        const to = log['topics']['2']

        const amount0In = parseInt('0x' + data.slice(0, 64), 16) / 1e18
        const amount1In = parseInt('0x' + data.slice(64, 128), 16) / 1e18
        const amount0Out = parseInt('0x' + data.slice(128, 192), 16) / 1e18
        const amount1Out = parseInt('0x' + data.slice(192, 256), 16) / 1e18

        const amount = amount0In > 0 ? amount0In : amount0Out
        const transactionData = {
          MPtoken: symbol,
          tokenIcon: tokenIcon,
          amount: parseInt(amount, 10),
          transactionId: result[i].transactionHash.slice(-6),
          transactionDate: moment(date).format('MMM Do, h:mm:ss a'),
          in: amount0In > 0 ? true : false,
          timestamp: log.timestamp
        }
        
        setTransactions(transactions => [...transactions, transactionData])
        _transactions.push(transactionData)
      }
      // setTransactions(_transactions )
    }
  }, [transactions]);

  return (
    <div className="lastTransaction">
      <div className="lastTransactionBody">
        <div className="font-16 color8 fw-lighter fontFamily-Roboto lastTransaction-title">Latest Transactions</div>
        <div className="p-2 pb-3">
          <div className="lastTransactionValueField">
            {
              txDisplay.map((item, i) => (
                <div key={i} className="d-flex justify-content-between align-items-center my-3">
                  <div className="d-flex align-items-center">
                    <img className="token-item-img me-2" src={item.tokenIcon} alt='icon' />
                    <div className="d-flex flex-column justify-content-between">
                      <div className="font-12 text-white fw-lighter fontFamily-Roboto">
                        {item.MPtoken}
                      </div>
                      <div className="font-10 color9 fw-lighter fontFamily-open">{item.transactionDate}</div>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <div className={`${item.in ? 'color10' : 'color77' } font-12 fw-lighter fontFamily-Roboto`}>
                      $ {item.amount}
                    </div>
                    <div className="color77 font-10 fontFamily-open">{item.transactionId}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className='buy-sell-title'>
        24 Hour Buy/Sell Pressure
      </div>
      <div className="d-flex align-items-center w-100">
        {
          sellsPercent == 0 || sellsPercent == 'NaN' ?
          <div className="nonChange-bar" style={{ width: '100%' }}>
            0%
          </div> :
          <div className='d-flex align-items-center w-100'>
            <div className={ sellsPercent == 100 ? "increase-bar increase-bar-border" : "increase-bar" } style={{ width: `${sellsPercent}%` }}>
              {sellsPercent}%
            </div>
            { sellsPercent == 100 ? "" : 
              <div className="decrease-bar" style={{ width: `${100-sellsPercent}%` }}>
                {100-sellsPercent}%
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}
export default LastTransaction