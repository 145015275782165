import React, { useState, useContext } from 'react'
import { BiChevronDown } from "react-icons/bi";
import { Link } from 'react-router-dom'
import { Context } from '../../../context/AppProvider'
import pancakeSwapIcon from '../../../assets/img/pcs-1@2x.png'
import safemoonSwapIcon from '../../../assets/img/safemoonSwap-icon.svg'


const SwapBar = () => {
  const { swapValues, tokens } = useContext(Context)
  const [ dex, setDex ]= useState("sfm")

  return (
    <div className="swapStateField">
      <div className="d-flex justify-content-between align-items-center token-style-bar margin-top-0 swap-header-title-mobile">
        <div className="d-flex align-items-center font-10 color5 fontFamily-open fw-normal currency border-right">
          <img className={ dex === "pck" ? "token-item-img" : "token-item-img-safeMoon" } src={ dex === "pck" ?  pancakeSwapIcon : safemoonSwapIcon } alt='icon' />
          <div className='position-relative'>
            <select
              value={dex}
              onChange={(e)=>setDex(e.target.value)}
            >
              <option value="sfm">Safemoon swap</option>
              <option value="pck">Pancake Swap</option>
            </select>
            <div className="select-arrowIcon">
              <BiChevronDown color="white" size={20} />
            </div>
          </div>
        </div>
        <div className="font-10 line-height-32 color5 fontFamily-open fw-normal price border-right swap-header-mobile">PRICE</div>
        <div className="font-10 line-height-32 color5 fontFamily-open fw-normal holderPosition border-right swap-header-mobile">24 Hour Volume</div>
        <div className="font-10 line-height-32 color5 fontFamily-open fw-normal chart border-right swap-header-mobile">CHART</div>
        <div className="font-10 line-height-32 color5 fontFamily-open fw-normal byToken swap-header-mobile">EXCHANGE</div>
      </div>

      {

        Object.keys(swapValues).map((key, i) => {
          const item = swapValues[key]
          const token = tokens[key]
          return (
            <div key={i} className="d-flex justify-content-between align-items-center token-details-bar">
              <div className="d-flex align-items-center font-14 color5 fw-normal currency border-right">
                <img className="token-item-img" src={item.tokenIcon} alt='icon' />
                <div className="font-14 color4 fontFamily-open fw-lighter ms-3">{item.MPtokenName}</div>
              </div>
              <div className="font-14 line-height-32 fontFamily-open color4 fw-normal price border-right">
                <span>Price:</span> 
                ${ dex === "pck" ? item.pancakePrice : item.safemoonPrice }</div>
              <div className="font-14 line-height-32 fontFamily-open color4 fw-normal holderPosition border-right"><span>Volume:</span>${token['volume'][dex]}</div>
              <div className="font-14 line-height-32 fontFamily-open color4 fw-normal chart border-right">
                <img className="chartbar" src={ dex === "pck" ? item.pancakeSwapTokenChart: item.safeMoonSwapTokenChart } alt='chart' />
              </div>
              <div className="font-14 line-height-32 color5 fw-normal byToken">
                <button className="byToken-btn">
                  <Link
                    className='byToken-text'
                    to={ dex === "pck" ? `https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&inputCurrency=${token.tokenAddress}`: "https://swap.safemoon.com/" } 
                    target="_blank"
                  >
                    Buy Token
                  </Link>
                </button>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default SwapBar