import React from 'react'
import TokenChart from './TokenChart'
import TokenValueBar from './TokenValueBar';
import SwapBar from './SwapBar';

const DashboardBody = () => {
  return (
    <div className="tokenDashboardBody">
      <TokenValueBar />
      <TokenChart />
      <SwapBar />
    </div>
  )
}
export default DashboardBody