export const BINANCE_RPC_URL = 'https://bscrpc.com'

export const WSS_BINANCE_RPC_URL = 'wss://bsc-mainnet.nodereal.io/ws/v1/5e6a4a83b4fc4e61a644d365a711a159'

export const RAPID_API_URL='coinranking1.p.rapidapi.com'
export const RAPID_API_KEY='838e005194msh556ad28e9fd5103p1f990ejsn764a20a9d1d3'

export const ANKR_API_URL = 'https://rpc.ankr.com/multichain'

export const DEX_SCREENER_API_URL = 'https://api.dexscreener.com'

export const BUSD_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'

export const WBNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"