
import React, { useEffect, useState } from 'react';
import Ticker from 'react-ticker';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import increaseIcon from '../../../assets/img/arrow-5.svg'
import decreaseIcon from '../../../assets/img/arrow-6.svg'
import { getCoinsBySymbols } from '../../../services/rapidService';

const NativeTokenBar = () => {
  const [ nativeTokenData, setNativeTokenData ]= useState();

  useEffect(() => {
    let active = true
    const coinNames = [
      'BTC', 
      'BNB',
      'ETH',
      'XRP',
      'MATIC',
      'ADA',
      'LINK',
    ]
    const init = async () => {
      if (active) {
        const res = await getCoinsBySymbols(coinNames)
        setNativeTokenData(res.data.data.coins)
      }
    }
    init()
    return () => { active = false }
  }, [])

  return (
    <div className="tokenTypeBar mt-3">
      {nativeTokenData && <Ticker move={true}>
        { ({ index }) => (
          <div className='d-flex itemSet'>
            { 
              nativeTokenData.map((item, i) => (
                <div key={i} className="d-flex justify-content-between item">
                  <div className="d-flex flex-column align-items-center">
                    <img className="coin-icon mb-1" src={item.iconUrl} alt='coin icon' />
                    <div className="font-14 fw-bold">{item.name}</div>
                    <div className="font-10">${parseFloat(item.price).toFixed(2)}</div>
                  </div>
                  <Sparklines 
                    data={item.sparkline}
                    width={50} 
                    height={30} 
                    margin={5}
                  >
                    <SparklinesLine color={ item.change.includes('-')? "#EA3A3D": "#1AD598" } />
                  </Sparklines>
                  <div className="d-flex flex-column align-items-end justify-content-center">
                    <div className="font-18 text-white">
                      ${(parseFloat(item.price*item.change/100)).toFixed(2)}
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <div className={ item.change.includes('-') === false ? "changeIcon changeIcon-increase" :  "changeIcon changeIcon-decrease"}>
                        <img src={ item.change.includes('-') === false ? increaseIcon : decreaseIcon} alt='icon' />
                      </div>
                      {
                        item.change.includes('-')? 
                        <div className="font-14 color10 ms-1">{item.change} %</div>
                        :
                        <div className="font-14 color3 ms-1">+{item.change} %</div>
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        )}
      </Ticker>}
    </div>
  )
}
export default NativeTokenBar