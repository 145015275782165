import React, { useEffect, useContext, useCallback } from "react";
import Sidebar from './Sidebar';
import HeaderBar from '../../components/HeaderBar';
import NativeTokenBar from './NativeTokenBar';
import DashboardBody from './DashboardBody';
import Portfolio from './Portfolio';
import { Context } from "../../context/AppProvider";
import { getBalance, getTokenPrice } from "../../services/web3Service";
import { getVolume } from '../../services/dexScreenerService';
import { /*tokenPriceInUSD,*/ getData } from "../../services/dexScreenerService";
import { getTokenPriceHistory } from "../../services/binanceService";
import RouterPK from '../../abis/router_pk.json'
import RouterSM from '../../abis/router_sm.json'
import BNB_MP from '../../abis/bnb_mp.json';
import BTC_MP from '../../abis/btc_mp.json';
import { isValidAddress } from "../../utils/util";

const Dashboard = () => {
  const { walletAddress } = useContext(Context);
  const { tokens, setTokens, balanceHistory: vh, setBalanceHistory, priceHistory: ph, setPriceHistory, loading, setLoading } = useContext(Context)
  const { swapValues, setSwapValues } = useContext(Context);

  const getTokenData = useCallback(async () => {
    const _tokens = Object.assign({}, tokens)

    await Promise.all(Object.keys(_tokens).map(async (symbol) => {
      let token = _tokens[symbol]
      const pairAddress = token['lpAddress'];
      const TOKEN_ABI = token['tokenAbi'];
      const TOKEN_ADDRESS = token['tokenAddress'];
      //const _tokenPrice = await tokenPriceInUSD(pairAddress)
      const _tokenPrice = await getTokenPrice({
        tokenAddress: token['tokenAddress'],
        routerAbi: RouterSM.abi,
        routerAddress: RouterSM.address
      });
      const _getData = await getData(pairAddress)
      const _priceChange = _getData.priceChange
      const _buys = _getData.buys
      const _sells = _getData.sells
      _tokens[symbol]['balance'] = 'N/A'
      let balance = 0
      // console.log('get token data: ', walletAddress)
      if (walletAddress && isValidAddress('', walletAddress)) {
        const _balance = await getBalance({TOKEN_ABI, TOKEN_ADDRESS, walletAddress})
        const str_a = _balance.toString();
        balance = Number(str_a.slice(0, -18));
        _tokens[symbol]['balance'] = balance
        // console.log('balance: ', balance)
      }
  
      _tokens[symbol]['tokenPrice'] = _tokenPrice
      _tokens[symbol]['priceChange'] = _priceChange
      _tokens[symbol]['buys'] = _buys
      _tokens[symbol]['sells'] = _sells
      setTokens(prev => ({
        ...prev,
        [symbol]: {
          ...prev[symbol],
          tokenPrice: _tokenPrice,
          priceChange: _priceChange,
          buys: _buys,
          sells: _sells,
          balance
        }
      }))
    }))
    // setTokens(_tokens)

  }, [walletAddress])
  
  const getVolumes = async () => {

    const _tokens = Object.assign({}, tokens)
    Object.keys(_tokens).reduce(async (previous, key) => {
      await previous;
      const item = _tokens[key]
      return new Promise(async (resolve) => {
        const volume_pck = await getVolume(item.lps.pck, 'h24')
        const volume_sfm = await getVolume(item.lps.sfm, 'h24')

        setTokens(prev => ({
          ...prev,
          [key]: {
            ...prev[key],
            volume: {
              pck: volume_pck,
              sfm: volume_sfm,
            }
          }
        }))
        resolve()
      })
    }, Promise.resolve())
  }
  useEffect(() => {
    const effect = async () => {
      setLoading(true)
      const _swapValues = Object.assign({}, swapValues)
      const btc_mp_pancakePrice = await getTokenPrice({
        tokenAddress: BTC_MP.address,
        routerAbi: RouterPK.abi,
        routerAddress: RouterPK.address
      })
      const btc_mp_safemoonPrice = await getTokenPrice({
        tokenAddress: BTC_MP.address,
        routerAbi: RouterSM.abi,
        routerAddress: RouterSM.address
      })
      const bnb_mp_pancakePrice = await getTokenPrice({
        tokenAddress: BNB_MP.address,
        routerAbi: RouterPK.abi,
        routerAddress: RouterPK.address
      })
      const bnb_mp_safemoonPrice = await getTokenPrice({
        tokenAddress: BNB_MP.address,
        routerAbi: RouterSM.abi,
        routerAddress: RouterSM.address
      })
      _swapValues['btc_mp']['pancakePrice'] = btc_mp_pancakePrice.toFixed(5)
      _swapValues['btc_mp']['safemoonPrice'] = btc_mp_safemoonPrice.toFixed(5)
      _swapValues['bnb_mp']['pancakePrice'] = bnb_mp_pancakePrice.toFixed(5)
      _swapValues['bnb_mp']['safemoonPrice'] = bnb_mp_safemoonPrice.toFixed(5)
      
      
      setSwapValues(_swapValues)
      setTokens(prev => ({
        ...prev,
        'bnb_mp': {
          ...tokens['bnb_mp'],
          price: {
            pck: bnb_mp_pancakePrice,
            sfm: bnb_mp_safemoonPrice
          }
        },
        'btc_mp': {
          ...tokens['btc_mp'],
          price: {
            pck: btc_mp_pancakePrice,
            sfm: btc_mp_safemoonPrice
          }
        }
      }))
      await getVolumes()
      await init()
      // console.log('end loading')
      setLoading(false)
    }
    effect()
    // init()
  }, [])

  useEffect(() => {
    const effect = async () => {
      // setLoading(true)
      if (!loading)
        await getTokenData()
      // setLoading(false)
    }
    effect()
  }, [walletAddress, getTokenData, loading])

  const init = async () => {
    
    const tokenSymbols = Object.keys(tokens)
    const _ph = Object.assign({}, ph)
    
    const apiCall = endpoint => new Promise(async (resolve) => {
      const token = tokens[endpoint] 
      const data = await getTokenPriceHistory(token.tokenAddress)
      await (new Promise(resolve => setTimeout(resolve, 100)))
      _ph[endpoint] = data
      setPriceHistory(_ph)
      setTimeout(resolve, 200)
    });

    const reduceApiEndpoints = async (previous, endpoint) => {
      await previous;
      return apiCall(endpoint);
    };
    await tokenSymbols.reduce(reduceApiEndpoints, Promise.resolve());
  }

  return (
    <div className="x02-dashboard px-2">
      <div className="x02Dashboard1">
        <Sidebar />
        <div className="token-dashboard mx-2">
          <HeaderBar />
          <NativeTokenBar />
          <div className="mainBody">
            <DashboardBody />
            <Portfolio />
          </div>
        </div>  
        <div className="sidebar-footer-mobile">
          <div className="copyright-title">© 2022 The Mirror Protocol</div>
          <div className="copyright-text mt-1">Powered by BlockBusters Tech Inc.</div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard
