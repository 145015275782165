import BNB_MP from '../abis/bnb_mp.json'
import BNB_MP_LP from '../abis/bnb_mp_lp.json'
import BTC_MP from '../abis/btc_mp.json'
import BTC_MP_LP from '../abis/btc_mp_lp.json'

const defaultToken = {
  price: {
    pck: 0,
    sfm: 0,
  },
  volume: {
    pck: 0,
    sfm: 0,
  }
}
export const initialTokens = {
  bnb_mp: {
    lpAbi: BNB_MP_LP.abi,
    tokenAbi: BNB_MP.abi,
    lpAddress: BNB_MP_LP.address,
    tokenAddress: BNB_MP.address,
    tokenIcon: '/img/bnb3d-2@2x.png',
    tokenSymbol: 'BNB-MP',
    tokenType: 'bnb_mp',
    getTokenUrl: "https://thebittimes.com/token-BNB-MP-BSC-0x3297abae220272b79a134fea9b1cda413a6d95f4.html",
    lps: {
      pck: '0xFDB18DD9aEb7De36b7ed90A1DB664F66B925184b',
      sfm: '0x1348f4619E3ea97fac5F1E82c88970ECeB59Ef55', //pair
    },
    ...defaultToken,
  },
  btc_mp: {
    lpAbi: BTC_MP_LP.abi,
    tokenAbi: BTC_MP.abi,
    lpAddress: BTC_MP_LP.address,
    tokenAddress: BTC_MP.address,
    tokenIcon: '/img/btc3d-2@2x.png',
    tokenSymbol: 'BTC-MP',
    tokenType: 'btc_mp',
    getTokenUrl: "https://bscscan.com/address/0xaa16492d95f80e488ac997933abe7aa45d838ada",
    lps: {
      pck: '0x29f24a20Ca6233e176151df5EE01160039Df36BA',
      sfm: '0x917E64a992639D45FB2e802e5f6ed5A7Ee768dC5', //pair
    },
    ...defaultToken
  }
}

export const initialSwapValues = {
  bnb_mp: {
    MPtokenName: "BNB-MP",
    tokenIcon: "/img/bnb3d-2@2x.png",
    pancakeSwap24HourVolume: 350.35,
    pancakeSwapTokenChart: "/img/graph-4@2x.png",
    safeMoonSwap24HourVolume: 353.35,
    safeMoonSwapTokenChart: "/img/graph-2@2x.png",
  },
  btc_mp: {
    MPtokenName: "BTC-MP",
    tokenIcon: "/img/btc3d-2@2x.png",
    pancakeSwap24HourVolume: 209.05,
    pancakeSwapTokenChart: "/img/graph-5@2x.png",
    safeMoonSwap24HourVolume: 182.05,
    safeMoonSwapTokenChart: "/img/graph-4@2x.png",
  }
}