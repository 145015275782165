import { getPairs } from "../utils/dexScreenerApi"

/**
 * 
 * @param {*} tokenAddress 
 * @returns token price in usd currency
 * @example
 * 
 * const tokenPrice = await tokenPriceInUSD(0x...A)
 */
export const tokenPriceInUSD = async (pairAddress) => {
  const res = await getPairs(pairAddress)
  return res.data && res.data.pair ? res.data.pair['priceUsd'] : 'NA'
}

/**
 * 
 * @param {*} pairAddress 
 * @param {*} duration 'h24' or 'h6' or 'h1' or 'm1'
 */
export const getVolume = async (pairAddress, duration) => {
  const res = await getPairs(pairAddress)

  return res.data && res.data.pair ? res.data.pair['volume'][duration] : 'NA'
}
export const getBalance = async (tokenAddress, walletAddress) => {

}

/**
 * Get buying/selling pressure graph. This will be calculated over a 24 hour period
 * @param {*} pairAddress 
 * @returns 
 */
export const getPressureGraph = async (pairAddress) => {
  let buys = 0
  let sells = 0

  const res = await getPairs(pairAddress)
  if (res && res.data && res.data.pair) {
    const pair = res.data.pair
    buys = pair['txns']['h24']['buys']
    sells = pair['txns']['h24']['sells']
  }
  
  return { buys, sells }
}

/**
 * 
 * @param {*} pairAddress 
 * @returns 
 */
export const getData = async (pairAddress) => {
  const res = await getPairs(pairAddress)

  let priceChange = 0
  let buys = 0
  let sells = 0
  let volume = 0
  let tokenPriceUSD = 0

  if (res && res.data && res.data.pair) {
    const pair = res.data.pair
    buys = pair['txns']['h24']['buys']
    sells = pair['txns']['h24']['sells']
    tokenPriceUSD = res.data.pair['priceUsd']
    tokenPriceUSD = tokenPriceUSD ? tokenPriceUSD : 'NA'
    volume = pair['txns']['h24']['volume']
  } else {
    tokenPriceUSD = 'NA'
    volume = 'NA'
  }

  return {
    priceChange,
    buys,
    sells,
    volume,
    tokenPriceUSD,
  }
}
