import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';

import "./styles/globals.css"
import "./styles/style-guide.css"
import "./pages/Dashboard/dashboard.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);
