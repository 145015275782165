import React from "react";
import { 
  BrowserRouter,
  Routes,
  Route, } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import { AppProvider } from "./context/AppProvider";

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Dashboard />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
