import Web3 from 'web3'
import { BINANCE_RPC_URL, BUSD_ADDRESS, WSS_BINANCE_RPC_URL } from '../config';
import { getCurrentBlockNumber, getPastLogs } from '../utils/web3Api';
import { WBNB } from '../config';
import { getBlockNumberByTimestamp } from '../utils/binanceApi';

/**
 * 
 * @param {*} payload 
 * @returns 
 */
export const getBalance = async (payload) => {

  const {
    TOKEN_ABI, TOKEN_ADDRESS, walletAddress
  } = payload

  let _web3 = new Web3(BINANCE_RPC_URL);

  const TOKEN = new _web3.eth.Contract(TOKEN_ABI, TOKEN_ADDRESS)

  return await TOKEN.methods.balanceOf(walletAddress).call()
}

export const getTokenPrice = async (payload) => {
  const { tokenAddress, routerAbi, routerAddress } = payload

  const _web3 = new Web3('https://bscrpc.com');
  const router = new _web3.eth.Contract(routerAbi, routerAddress)
  const amount = _web3.utils.toBN((1e18).toString());
  const outputs = await router.methods.getAmountsOut(amount, [tokenAddress, WBNB, BUSD_ADDRESS]).call()

  return Promise.resolve(Number(outputs[2]) / 1e18)
}

const abi = {
  "anonymous": false,
  "inputs": [
    {
      "indexed": true,
      "internalType": "address",
      "name": "sender",
      "type": "address"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "amount0In",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "amount1In",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "amount0Out",
      "type": "uint256"
    },
    {
      "indexed": false,
      "internalType": "uint256",
      "name": "amount1Out",
      "type": "uint256"
    },
    {
      "indexed": true,
      "internalType": "address",
      "name": "to",
      "type": "address"
    }
  ],
  "name": "Swap",
  "type": "event"
}

const step = 9000

export const subscribe = async (payload) => {
  const { address, tokenName, symbol, callback } = payload
  // let _web3 = new Web3('wss://indulgent-holy-meme.bsc.discover.quiknode.pro/c02b2926b6835dd341976368099d9a58011d8ff3/');
  let _web3 = new Web3(WSS_BINANCE_RPC_URL);
  const topic = _web3.eth.abi.encodeEventSignature(abi);
  
  const currentBlock = await getCurrentBlockNumber()
  
  const from = currentBlock - step
  const to = currentBlock
  
  const result = await getPastSwapLogs({ web3: _web3, topic, from, to, ...payload, data: [] })
  callback(tokenName, symbol, result)

  _web3.eth.subscribe('logs', {
    address: address,
    topics: [topic],
  }, async function (error, result) {
    if (error) throw error

    if (result && result.length > 0) {
      const _result = await Promise.all(result.map(async (item) => {
        const timestamp = (await _web3.eth.getBlock(item.blockNumber))['timestamp']
        return Promise.resolve({
          ...item,
          timestamp
        })
        
      }))
      callback(tokenName, symbol, _result)
    }
  })
}
export const getPastSwapLogs = async (payload) => new Promise(async (resolve) => {

  const { data, from, to /*, tokenName*/ } = payload
  if (data.length > 10) { resolve(data) }

  else {
      const result = await getPastLogs(payload)
      setTimeout(async () => {
        // resolve([])
        resolve(await getPastSwapLogs({ ...payload, from: from - step, to: to - step, data: [...data, ...result]}))
      }, 900)
  }
})

export const getVolume = async (payload) => {
  //const { address, duration, callback } = payload

  const web3 = new Web3(WSS_BINANCE_RPC_URL);
  const topic = web3.eth.abi.encodeEventSignature(abi)

  const from = await getCurrentBlockNumber()
  const to = await getBlockNumberByTimestamp(new Date().getTime() - 24 * 60 * 1000)
}
