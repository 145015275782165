import React, { useContext } from 'react'
import PortfolioChart from './PortfolioChart';
import { Context } from '../../../context/AppProvider';

const PortfolioValue = () => {
  const { currentToken, setCurrentToken } = useContext(Context);

  return (
    <div className="portfolioValue">
      <div className='portfolioValueBody'>
        <div className="d-flex justify-content-center align-items-center portfolioTitleBar">
          <div style={{fontSize: 14}} className="font-14 text-center fontFamily-open text-white">{`Your ${currentToken === 'bnb_mp' ? 'BNB-MP' : 'BTC-MP'} Token History`}</div>
        </div>

        <PortfolioChart />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button className="d-flex align-items-center thisWeek">
          <div className="point-symbol me-1 color6"></div>
          This Week
        </button>
        <button className="d-flex align-items-center lastWeek">
          <div className="point-symbol me-1 color66"></div>
          Last Week
        </button>
      </div>
    </div>
  )
}
export default PortfolioValue