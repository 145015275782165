export const initialOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },

    tooltip: {
      backgroundColor: "#384455",
      titlFontColor: "#FFFFFF",
      titleColor: "#FFFFFF",
      titleMarginBottom: 8,
      titleSpacing: 3,
      bodyColor: "white",
      displayColors: false,
      padding: 10,
      boxWidth: 50,
      bodySpacing: 8,
      usePointStyle: false,
      bodyFont: {
          size: 16,
          lineHeight: "19px",
          family: "'Open Sans'",
      },
      filter: function (tooltipItem, data) {
        if (tooltipItem.parsed.y !== 0) {
          return true;
        }
      },
      callbacks: {
        labelPointStyle: function (context) {
          return {
            pointStyle: "circle",
            rotation: 0
          };
        },
        label: function (context) {
          var label = "";
          if (context !== null && context !== undefined && context !== "") {
            label = context.dataset.label;
            if (
              context.parsed.y !== null &&
              context.parsed.y !== undefined
            ) {
              label = "$" + context.parsed.y;
            }
          }
          return label;
        }
      }
    }
  },
};

export const initialChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      fill: false,
      data: [1,2,2,2,2],
      lineTension: 0.4,
      borderColor: ['#DB5AEE'],
      borderWidth: 2,
      pointBackgroundColor: "none",
      pointBorderColor: "#DB5AEE",
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "none",
      pointHoverBorderColor: "#DB5AEE",
      pointHoverBorderWidth: 3,
      pointRadius: 1,
      pointHitRadius: 10,
    },
  ]
}
