import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../context/AppProvider';
import { getTokenHolderPosition } from '../../../services/ankrService';
import { isValidAddress } from '../../../utils/util';

const TokenValueBar = () => {
  const { selectedTokenData, tokens, currentToken } = useContext(Context);
  const { walletAddress } = useContext(Context);
  const [ holderPosition, setHolderPosition ] = useState('N/A');

  useEffect(()=> {
    if (isValidAddress('', walletAddress)) {
      (async () => {
        const tokenAddress = tokens[currentToken].tokenAddress;
        const res = await getTokenHolderPosition(walletAddress, tokenAddress)
        setHolderPosition(res)
      })()
    } else {
      setHolderPosition('N/A')
    }
  }, [walletAddress, currentToken])

  return (
    <div className='tokenValueBar'>
      <div className="d-flex justify-content-between align-items-center token-style-bar">
        <div className="font-10 color5 fw-normal fontFamily-open currency border-right">Token Name</div>
        <div className="font-10 color5 fw-normal fontFamily-open price border-right">PRICE</div>
        <div className="font-10 color5 fw-normal fontFamily-open holderPosition border-right">Holder Position</div>
        <div className="font-10 color5 fw-normal fontFamily-open chart border-right">Chart</div>
        <div className="font-10 color5 fw-normal fontFamily-open byToken">Buy The Token</div>
      </div>

      <div className="d-flex justify-content-between align-items-center token-details-bar">
        <div className="d-flex align-items-center font-14 color5 fw-normal currency border-right">
          <img className="token-item-img" src={selectedTokenData?.tokenIcon} alt='icon' />
          <div className="font-14 ms-3">{selectedTokenData?.tokenSymbol}</div>
        </div>
        <div className="font-14 line-height-32 fw-normal fontFamily-open color4 price border-right">
          ${parseFloat(selectedTokenData?.tokenPrice).toFixed(5)}
        </div>
        <div className="font-14 line-height-32 fw-normal fontFamily-open color4 holderPosition border-right">{holderPosition}</div>
        <div className="font-14 line-height-32 fw-normal fontFamily-open color4 chart border-right">
          <img className="chartbar" src='/img/graph-5@2x.png' alt='chart' />
        </div>
        <div className="font-14 color5 fw-normal byToken">
          <button className="getToken-btn">
            <Link
              className='getToken-text'
              to={`https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&inputCurrency=${tokens[currentToken].tokenAddress}`} 
              target="_blank"
            >
              Get {selectedTokenData?.tokenSymbol}
            </Link>
          </button>
        </div>
      </div>
    </div>
  )
}

export default TokenValueBar
