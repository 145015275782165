import { ANKR_API_URL } from "../config"
import { apiPostRequest } from "./axios"

/**
 * 
 * @param {token address} tokenAddress 
 * @returns 
 */
export const getTokenHolders = async (tokenAddress) => {
  const payload = {
      "jsonrpc": "2.0",
      "method": "ankr_getTokenHolders",
      "params": {
          "blockchain": "bsc",
          "contractAddress": `${tokenAddress}`,
          "pageSize": 0,
          "pageToken": ""
      },
      "id": 1
  }
  return await apiPostRequest(`${ANKR_API_URL}`, payload)
}

export const getTxnByHash = async (payload) => {
  const option = {
      "jsonrpc": "2.0",
      "method": "ankr_getTransactionsByHash",
      "params": {
          "transactionHash": payload,
          "decodeLogs": true,
          "decodeTxData": true
      },
      "id": 1
  }
  
  return await apiPostRequest(`${ANKR_API_URL}`, option)
}