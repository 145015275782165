import React, { useContext, useEffect, useState } from "react"; 
import { BiChevronDown } from "react-icons/bi";
import { Line } from 'react-chartjs-2';
import { Default } from "react-awesome-spinners";
import { Context } from "../../../context/AppProvider";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { initialChartData, initialOptions } from "./data";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend 
);

const TokenChart = () => {
  const { tokens } = useContext(Context)
  const { currentToken, setCurrentToken } = useContext(Context);
  const { priceHistory: ph } = useContext(Context)
  const [ chartData, setChartData ] = useState(initialChartData)
  const [ isloading, setIsLoading ]= useState(false)
  
  useEffect(() => {
    console.log("current", ph);
    const data = ph[currentToken]
    if(data.values.length === 10 ) {
      setIsLoading(true)
    }
    else {
      setIsLoading(false)
    }

    if (data && data.values.length > 0) {

      setChartData(
        {
          ...chartData,
          labels: data.labels.map(label => label.length >= 10 ? (new Date(Number(label) * 1000).toISOString().slice(0, 10)) : label),
          datasets: [
            {
              ...chartData['datasets'][0],
              data: data.values,
            },
          ]
        }
      )
    }
  }, [ph, currentToken])


  return (
    <div className="tokenChart">
      <div className="title-bar">
        <div className="d-flex align-items-center">
          <img className="token-item-img" src={tokens[currentToken]?.tokenIcon} alt="icon" />
          <div className="position-relative">
            <select
              value={currentToken}
              onChange={(e)=>setCurrentToken(e.target.value)}
            >
              <option value="bnb_mp">BNB-MP</option>
              <option value="btc_mp">BTC-MP</option>
              <option value="xrp_mp" disabled style={{color: '#b0aeae'}}>XRP-MP</option>
              <option value="eth_mp" disabled style={{color: '#b0aeae'}}>ETH-MP</option>
              <option value="matic_mp" disabled style={{color: '#b0aeae'}}>MATIC-MP</option>
              <option value="ada_mp" disabled style={{color: '#b0aeae'}}>ADA-MP</option>
              <option value="link_mp" disabled style={{color: '#b0aeae'}}>LINK-MP</option>
            </select>
            <div className="select-arrowIcon">
              <BiChevronDown color="white" size={20} />
            </div>
          </div>
        </div>

        <div className="price-volume">
          <div className="d-flex justify-content-center align-items-center price">
            <div className="point"></div>
            <div className="font-12 fw-normal color2 ms-1">Price</div>
          </div>
        </div>
      </div>

      <div className="mt-3 chart-bar">
        {
          isloading ?
          <div className="chart-loading-field ">
            <div className="chart-loading">
              <Default size={30} />
            </div>
          </div>
          :
          <Line
            options={initialOptions} 
            data={chartData}
          />

        }
      </div>
    </div>
  )
}
export default TokenChart