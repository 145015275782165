import { useEffect, useState, useContext } from 'react'
import { Context } from '../context/AppProvider'
import { getAccountBalanceHistory } from '../services/binanceService'
import { isValidAddress, sequential } from '../utils/util'

const initialData = {
  labels: new Array(10).fill(2).map((_, key) => key),
  values: new Array(10).fill(2).map(() => (0)),
}

const useBalanceHistory = (wallet) => {
  const { tokens, loading: globalLoading } = useContext(Context)
  const [loading, setLoading] = useState(false)

  const [balanceHistory, setBalanceHistory] = useState({
    bnb_mp: [],
    btc_mp: [],
  })
  
  useEffect(() => {
    // console.log('wallet address changed in hook')
    const effect = async () => {
      if (isValidAddress('', wallet) && !globalLoading) {
        setLoading(true)

        await getAccountBalanceHistory({ contract: `0x3297abAe220272b79A134FEA9B1CdA413a6d95F4`, address: wallet, duration: 600 })
        await sequential({
          arr: Object.keys(tokens),
          duration: 600,
          callback: async (endpoint) => {
            const contract = tokens[endpoint].tokenAddress
            const history = await getAccountBalanceHistory({ contract, address: wallet, duration: 600 })
            await (new Promise(resolve => setTimeout(resolve, 500)))
            setBalanceHistory(prev => ({
              ...prev,
              [endpoint]: history
            }))
          }
        })
        setLoading(false)
      }
    }
    effect()
  }, [wallet, globalLoading])

  return { balanceHistory, loading }
}

export default useBalanceHistory
