
export const getStepCeil = (n) => {
  if (typeof n === 'number') {
    const str = n.toString().replace('.', '')
    let d = 0
    for (let i = 0; i < str.length; i++) {
      const c = Number(str.at(i))
      if (c > 0) {
        return 20 / Math.pow(10, d)
      } else {
        d ++
      }
    }
  } else return 0
}

const getDigits = (n) => {
  let a = Number(n)

  if (a > 1) return a.toString().length
  else {
    let i = 0

    while (a < 1) {
      a *= 10
      i ++
    }
    return i
  }
}

export const getLimitCeil = (a, step, f) => {
  if (typeof a === 'number') {
    if (step > 1) {
      return f ? Math.ceil(a / 10) * 10 : Math.floor(a / 10) * 10
    } else {
      const d = getDigits(step)

      let round = Math.floor(a * Math.pow(10, d - 1)) * 10 / Math.pow(10, d)

      while (round < a) {
        round += step
      }

      return f ? round : round - step
    }
  } else return 0
}

export const getMax = (a, b) => {
  return a >= b ? a : b
}

export const getMin = (a, b) => {
  return a <= b ? a : b
}

export const isValidAddress = (type, address) => {
  return address && address.length === 42 && address.indexOf('0x') === 0
}

export const sequential = async (payload) => {
  const { arr, callback, duration } = payload

  const apiCall = (endpoint, total) => new Promise(async (resolve) => {
    const result = await callback(endpoint)
    setTimeout(() => resolve([...total, result]), duration)
  })
  const reduceApiEndpoints = async (previous, endpoint) => {
    const total = await previous
    return apiCall(endpoint, total)
  }
  const value = await arr.reduce(reduceApiEndpoints, Promise.resolve([]))
  return Promise.resolve(value)
}