import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../context/AppProvider'
import { isValidAddress } from '../utils/util'

const get2Digit = (n) => Number(n) >= 10 ? n : `0${n}`

const HeaderBar = () => {
  const {walletAddress, setWalletAddress} = useContext(Context)
  const [addressValidate, setAddressValidate] = useState(false)
  const [currentTime, setCurrentTime]= useState('');
  
  useEffect(() => {
    const interval = setInterval(() => {
      const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
      const date = new Date();
      const hour = get2Digit(date.getHours());
      const min = get2Digit(date.getMinutes());
      const day = get2Digit(date.getDate());
      const month = date.getMonth();
      const year = date.getFullYear();
      
      const displayDate = `${hour}:${min} at ${day}th ${months[month]} ${year}`
      setCurrentTime(displayDate)
    }, 1000);

    return () => clearInterval(interval)
  }, [])

  useEffect(()=> {
    setAddressValidate(isValidAddress('', walletAddress))
  }, [walletAddress])

  return (
    <div className="headerBar">
        <div className="d-flex flex-column">
          <div className="font-24 text-white">Token Dashboard</div>
          <div className="font-24 color2">{currentTime}</div>
        </div>

        <div className={ addressValidate === true? "position-relative search-field search-active" : "position-relative search-field search-border"}>
          <input 
            className="font-18 color2" 
            type='text' 
            placeholder="Enter Wallet Address" 
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)} 
          />

          {addressValidate === false && walletAddress !== "" && <div className='address-validate'>Address is not valid</div>}
          <img src="/img/search-icon.svg" alt="search icon" />
        </div>
    </div>
  )
}
export default HeaderBar