import { getTokenHolders } from "../utils/ankrApi"

export const getTokenHolderPosition = async (walletAddress, tokenAddress) => {
  const res = await getTokenHolders(tokenAddress)
  let totalHolders = 0
  let position = 0
  
  if (res && res.data.result) {
    let data = res.data.result
    totalHolders = data.holdersCount

    if (data.holders && data.holders.length > 0) {

      let holders = data.holders
      holders.sort((a, b) => b.balance - a.balance)
      holders.forEach((holder, index) => {
        if (holder.holderAddress.toLowerCase() === walletAddress.toLowerCase()) {
          position = index + 1
          return
        }
      })
    }
  }

  return `${position ? position : 'NA'} / ${totalHolders}`
}
