import React from 'react'
import PortfolioValue from './PorfolioValue'
import LastTransaction from './LastTransaction'
import portfolioImg from "../../../assets/img/fickceswyai8nwb-1@2x_update.png"

const Portfolio = () => {

  return (
    <div className="portfolio">
      <PortfolioValue />
      <LastTransaction />
      
      <div className='d-flex justify-content-center align-items-center portolio-image'>
        <img src={portfolioImg} alt='img' />
      </div>
    </div>
  )
}
export default Portfolio