import Web3 from 'web3'
import { BINANCE_RPC_URL } from '../config'

export const getPastLogs = async (payload) => new Promise(async (resolve, reject) => {
  const { web3, topic, address, from, to } = payload
  await web3.eth.getPastLogs({
    fromBlock: from,
    toBlock: to,
    address: address,
    topics: [topic]},
    async (error, result) => {
      if (error) throw error
      
      if (result && result.length > 0) {
        const _result = await Promise.all(result.map(async (item) => {
          const timestamp = (await web3.eth.getBlock(item.blockNumber))['timestamp']
          return Promise.resolve({
            ...item,
            timestamp
          })
          
        }))
        resolve(_result)
      } else {
        // callback([])
        resolve([])
      }
      // resolve(result || [])
    }
  )
})

export const getCurrentBlockNumber = async () => {
  return await (new Web3(BINANCE_RPC_URL).eth.getBlockNumber())
}

export const getTimeStamp = async (web3, block) => {
  return await web3.eth.getBlock(block).timestamp
}

// export const getBlockByTimeStamp = async (web3, start, timestamp) => {
//   const block = web3.eth.getBlock()
// }