import { getAccountBalance, getBlockNumberByTimestamp } from "../utils/binanceApi"
import { sequential } from "../utils/util"
import { apiTokenChart } from "../utils/axios";
import { format } from 'date-fns'

//const apiKey = '1M8XRFGM7IW5FAPMSFQCQ4BMJD944AVKGI'

export const getTokenPriceHistory = async (token) => {

  let labels = [];
  let values = [];

  await apiTokenChart.get("line-chart?tokenId=" + token + ":56&days=14")
    .then(res => {
      let dateLabels = res.data.labels;
      let prices = res.data.datasets[1].data;
      for(let i = 0; i < dateLabels.length; i ++) {
        labels.push(format(new Date(dateLabels[i]), "MM/dd"));
        values.push( prices[i].toFixed(5));
      }
  });
  return {
    values: values.map(item => item),
    labels: labels.map(item => item)
  }
}
export const getAccountBalanceHistory = async (payload) => {
  const {contract, address, duration} = payload

  let timestamp = parseInt(new Date().getTime() / 1000) - 24 * 60 * 60
  const arr = Array.from(Array(14).keys())

  const result = await sequential({
    arr, duration,
    callback: (index) => new Promise(async (resolve) =>{
      timestamp = timestamp - 24 * 60 * 60
      const block = (await getBlockNumberByTimestamp(timestamp)).data.result
      const balance = (await getAccountBalance({ contract, address, block })).data.result
      // console.log(block, balance)
      // data.push(balance)
      // return new Promise.resolve({ block, balance })
      resolve({ timestamp, balance })
    })
  })
  return Promise.resolve(result)
}
export const get24HoursVolume = async () => {

}