import { apiGetRequest } from "./axios"

const apiKey = process.env.REACT_APP_BSC_API_KEY || `1M8XRFGM7IW5FAPMSFQCQ4BMJD944AVKGI`

export const getTokenBalanceByBlock = async (contractAddress, address, blockNo, apiKey) => {
  return await apiGetRequest(
    `https://api.bscscan.com/api?module=account&action=tokenbalancehistory&contractaddress=${contractAddress}&address=${address}&blockno=${blockNo}&apiKey=${apiKey}`
  )
}

export const getBNBHistoricalPrice = async (start, end) => {
  return await apiGetRequest(
    `https://api.bscscan.com/api?module=stats&action=bnbdailyprice&startdate=${start}&enddate=${end}&sort=asc&apiKey=1M8XRFGM7IW5FAPMSFQCQ4BMJD944AVKGI`
  )
}

// export const getBNBBalanceForAccount = async (block,  contract) => {

// }

export const getBlockNumberByTimestamp = async (timestamp) => {
  return await apiGetRequest(
    `https://api.bscscan.com/api?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apiKey=1M8XRFGM7IW5FAPMSFQCQ4BMJD944AVKGI`
  )
}

export const getAccountBalance = async (payload) => {
  const { address, contract, block } = payload

  return await apiGetRequest(
    `https://api.bscscan.com/api?module=account&action=tokenbalancehistory&contractaddress=${contract}&address=${address}&blockno=${block}&apiKey=${apiKey}`)
}